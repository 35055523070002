.benefits {
  padding-top: 130px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  padding-bottom: 120px;
}

.benefits__wrapper {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.benefite__elipse {
  position: absolute;
  bottom: -120px;
  right: 216px;
  width: 150px;
  height: 150px;
  flex-shrink: 0;
  background-color: var(--brand-color);
  filter: blur(160px);
  z-index: -1;
  backdrop-filter: none;
  -webkit-backdrop-filter: blur(160px);
}

.benefite__elipse-two {
  display: none;
}

@media (max-width: 1200px) {
  .benefits {
    padding-top: 0px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    padding-bottom: 80px;
  }

  .benefits__wrapper {
    width: 320px;
    display: flex;
    flex-direction: column;
    justify-content: unset;
    align-items: center;
    gap: 90px;
    position: relative;
  }

  .benefite__elipse {
    position: absolute;
    bottom: unset;
    top: 288px;
    right: 21px;
    width: 80px;
    height: 80px;
    flex-shrink: 0;
    background-color: var(--brand-color);
    filter: blur(120px);
    backdrop-filter: none;
    -webkit-backdrop-filter: blur(120px);
  }

  .benefite__elipse-two {
    display: block;
    position: absolute;
    bottom: 0px;
    left: -13px;
    width: 80px;
    height: 80px;
    flex-shrink: 0;
    background-color: var(--brand-color);
    filter: blur(120px);  
    z-index: -1;
    backdrop-filter: none;
    -webkit-backdrop-filter: blur(120px);
  }
  
}
