.footer {
  margin-top: 60px;
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  background: #151517;
  align-items: center;
}

.footer__wrapper {
  height: 120px;
  width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer__phone {
  color: white;
  font-size: 20px;
}



@media (max-width: 1200px) {
  .footer {
    margin-top: 40px;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    background: #151517;
    align-items: center;
  }
  
  .footer__wrapper {
    height: 80px;
    width: 320px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .footer__phone {
    color: white;
    font-size: 16px;
  }
  
}