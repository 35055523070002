.about__us {
  width: 100%;
  display: flex;
  justify-content: center;
}

.about__us-wrapper {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
}

.about__us-texts {
  width: 513px;
}


.about__us-description {
  margin-top: 19.2px;
}

.about__us-description:nth-child(2) {
  margin-top: 25px;
}

.about__us-image {
  width: 567px;
  height: 590px;
  flex-shrink: 0;
  border-radius: 30px;
  object-fit: cover;
}

.about__us-elipse {
    position: absolute;
    bottom: 11px;
    left: -235px;
    width: 150px;
    height: 150px;
    flex-shrink: 0;
    background-color: var(--brand-color);
    filter: blur(160px);
    z-index: -1;
    backdrop-filter: none;
    -webkit-backdrop-filter: blur(160px);
}

.about__us-elipse-two {
  display: none;
}

@media (max-width: 1200px) {
  .about__us-wrapper {
    width: 320px;
    display: flex;
    justify-content: unset;
    flex-direction: column;
    position: relative;
    align-items: center;
    gap: 30px;
  }


.about__us-texts {
  width: 320px;
}

.about__us-description {
  margin-top: 17px;
  font-size: 14px;
}

.about__us-description:nth-child(2) {
  margin-top: 15px;
}

.about__us-image {
  width: 320px;
  height: 400px;
}

.about__us-elipse {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  background-color: var(--brand-color);
  filter: blur(120px);
  z-index: -1;
}

.about__us-elipse-two {
  display: block;
  position: absolute;
  bottom: 354px;
  right: 28px;
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  background-color: var(--brand-color);
  filter: blur(120px);
  z-index: -1;
  backdrop-filter: none;
  -webkit-backdrop-filter: blur(120px);
}

}