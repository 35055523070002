.steps__section {
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.steps__section-wrapper {
  width: 1200px;
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
  position: relative;
}


.steps {
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.steps__image {
  width: 496px;
  height: 740px;
  flex-shrink: 0;
  border-radius: 40px;
}

.steps__elipse {
  position: absolute;
  left: -185px;
  top: 319px;
  width: 150px;
  height: 150px;
  flex-shrink: 0;
  filter: blur(160px);
  background-color: var(--brand-color);
  z-index: -1;
  backdrop-filter: none;
  -webkit-backdrop-filter: blur(160px);
}

.steps__elipse-two {
  position: absolute;
  right: -50px;
  bottom: 162px;
  width: 150px;
  height: 150px;
  flex-shrink: 0;
  filter: blur(160px);
  background-color: var(--brand-color);
  z-index: -1;
  backdrop-filter: none;
  -webkit-backdrop-filter: blur(160px);
}

.steps__elipse-three {
  display: none;
}

@media (max-width: 1200px) {
  .steps__section {
    margin-top: 80px;
  }

  .steps__title {
    width: 320px;
  }
  
  .steps__section-wrapper {
    width: 320px;
    margin-top: 30px;
    display: flex;
    justify-content: unset;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  
  .steps {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  
  .steps__image {
    margin-top: 30px;
    width: 320px;
    height: 400px;
    border-radius: 40px;
  }

  .steps__elipse {
    position: absolute;
    left: 200px;
    top: 106px;
    width: 80px;
    height: 80px;
    flex-shrink: 0;
    filter: blur(120px);
    background-color: var(--brand-color);
    z-index: -1;
    backdrop-filter: none;
    -webkit-backdrop-filter: blur(120px);
  }
  
  .steps__elipse-two {
    position: absolute;
    right: 7px;
    bottom: -62px;
    width: 80px;
    height: 80px;
    flex-shrink: 0;
    filter: blur(120px);
    background-color: var(--brand-color);
    z-index: -1;
    backdrop-filter: none;
    -webkit-backdrop-filter: blur(120px);
  }

  .steps__elipse-three {
    position: absolute;
    display: block;
    top: 587px;
    width: 80px;
    height: 80px;
    left: 2px;
    filter: blur(120px);
    background-color: var(--brand-color);
    z-index: -1;
    backdrop-filter: none;
    -webkit-backdrop-filter: blur(120px);
  }
  
}