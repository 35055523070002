.step__benefite {
    width: 535px;
    display: flex;
    gap: 35px;
  }
  
  .step__benefite-number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    flex-shrink: 0;
    border-radius: 15px;
    background: #151517;
  }
  
  .step__benefite-texts {
      display: flex;
      flex-direction: column;
      gap: 8px;
  }

  .step__benefite-title {
    font-size: 24px;
  }
  
  .step__benefite-description {
      color: var(--white-90);
      width: 449px;
  }
  
  .step__benefite-number-mobile {
    display: none;
  }
  
  @media (max-width: 1200px) {
    .step__benefite {
      width: 320px;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  
    .step__benefite-number {
     display: none;
    }
  
    .step__benefite-header {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .step__benefite-description {
      margin-top: 10px;
        color: var(--white-90);
        width: 320px;
        font-size: 14px;
    }

    .step__benefite-title {
      font-size: 21px;
    }
    
  
    .step__benefite-number-mobile {
      display: none;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 60px;
      flex-shrink: 0;
      border-radius: 10px;
      background: #151517;
      font-size: 36px;
    }
  }