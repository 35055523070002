.benefite {
  width: 260px;
  height: 241px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #151517;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.benefite__icon-wrapper {
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  background-color: var(--brand-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.beenefite__icon {
    width: 50px;
    height: 50px;
}

.benefite__title {
    font-size: 22px;
    margin-top: 80px;
}

.benefite__description {
    text-align: center;
    width: 199px;
}

@media (max-width: 1200px) {
  .benefite {
    width: 320px;
  }
}