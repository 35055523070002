/* Arial font import */
@font-face {
  font-family: "Arial-B";
  src: url("./assets/fonts/arial/ARIALBD.TTF");
  font-weight: normal;
  font-style: normal;
}

/* Montserrat font import */

@font-face {
  font-family: "Montserrat-B";
  src: url("./assets/fonts/Montserrat/Montserrat-Bold.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat-SB";
  src: url("./assets/fonts/Montserrat/Montserrat-SemiBold.ttf");
  font-weight: normal;
  font-style: normal;
}

/* Inter font import */

@font-face {
  font-family: "Inter-M";
  src: url("./assets/fonts/Inter/Inter-Medium.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter-SB";
  src: url("./assets/fonts/Inter/Inter-SemiBold.ttf");
  font-weight: normal;
  font-style: normal;
}

/* Colors */
:root {
  --white: #fff;
  --white-90: rgba(255, 255, 255, 0.9);
  --white-70: rgba(255, 255, 255, 0.7);
  --white-60: rgba(255, 255, 255, 0.6);
  --brand-color: #44d62c;
  --body-text: rgba(255, 255, 255, 0.8);
}

/* Reset */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}

/* Body Styles */
body {
  margin: 0;
  padding: 0;
  background: #080808;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-family: "Montserrat Alternates", sans-serif; */
}

img {
  max-width: 100%;
  display: block;
}

/* Form Element Styles */
input,
button,
select {
  font: inherit;
}

/* Button Styles */
button {
  cursor: pointer;
}

a {
  text-decoration-line: none;
}

h2 {
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-weight: normal;
  line-height: normal;
  color: inherit;
  background: none;
  border: none;
  outline: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: inherit;
}

::-webkit-scrollbar-thumb {
  background: var(--brand-color);
  border-radius: 5px;
}

.brand-color {
  color: var(--brand-color);
}

.arial-b {
  font-family: "Arial-B";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.mont-b {
  font-family: "Montserrat-B";
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.mont-sb {
  font-family: "Montserrat-SB";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.int-m {
    font-family: "Inter-M";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px; 
}

.int-sb {
    color: #000;
    font-family: "Inter-SB";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

@media (max-width: 1200px) {
  .arial-b {
    font-family: "Arial-B";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .int-sb {
    color: #000;
    font-family: "Inter-SB";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.mont-b {
  font-family: "Montserrat-B";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

}