.modal__form {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
  left: 0;
  top: 0;
  opacity: 0;
}

.modal__form.active {
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: 5;
}

.modal__form-overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(7.5px);
  -webkit-backdrop-filter: blur(7.5px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__form-body {
  padding: 80px 100px 60px 80px;
  width: 747px;
  height: 444px;
  flex-shrink: 0;
  border-radius: 30px;
  background: #151517;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.modal__form-body.hidden {
  display: none;
}

.modal__form-body-response {
  opacity: 0;
  z-index: -10;
  position: absolute;
}

.modal__form-body-response.active {
  padding: 80px 100px 60px 80px;
  width: 747px;
  height: 444px;
  flex-shrink: 0;
  border-radius: 30px;
  background: #151517;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  opacity: 1;
  z-index: 5;
}

.sended-icon {
  width: 150px;
  height: 150px;
  flex-shrink: 0;
}

.sended-title {
  margin-top: 41px;
  font-size: 36px;
}

.sended-description {
  margin-top: 40px;
  font-size: 24px;
}

.close__icon {
  position: absolute;
  width: 34px;
  height: 34px;
  transform: rotate(45deg);
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.modal__form-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal__form-form-wrapper {
  display: flex;
  flex-direction: column;
}

.modal__form-description {
  margin-top: 14px;
  width: 523px;
  font-size: 24px;
}

.form__inputs-wrapper {
  margin-top: 40px;
  width: 547px;
  display: flex;
  justify-content: space-between;
}

.modal__form-input {
  outline: none;
  border: none;
  border-radius: 10px;
  background: #080808;
  width: 248px;
  height: 58px;
  flex-shrink: 0;
  color: white;
  font-size: 16px;
  padding-left: 18px;
  border: 1px solid #080808;
}

.modal__form-input::placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.modal__form-input.error {
  border: 1px solid red;
  color: red;
}

.modal__form-button {
  margin-top: 50px;
  width: 300px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 10px;
  background: var(--brand-color);
  border: none;
  color: black;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .3s;
}

.modal__form-button:hover {
  background: #24ff00;
}

@media (max-width: 1200px) {
  .modal__form-body {
    padding: 50px 20px 60px 20px;
    width: 320px;
    height: 530px;
    flex-shrink: 0;
    border-radius: 30px;
    background: #151517;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .close__icon {
    position: absolute;
    width: 30px;
    height: 30px;
    transform: rotate(45deg);
    top: 19px;
    right: 19px;
    cursor: pointer;
  }

  .modal__form-description {
    margin-top: 10px;
    width: 280px;
    font-size: 21px;
  }

  .form__inputs-wrapper {
    margin-top: 30px;
    width: 280px;
    display: flex;
    justify-content: unset;
    flex-direction: column;
    gap: 30px;
  }

  .modal__form-input {
    outline: none;
    border: none;
    border-radius: 10px;
    background: #080808;
    width: 278px;
    height: 58px;
    flex-shrink: 0;
    color: white;
    font-size: 16px;
    padding-left: 18px;
    border: 1px solid #080808;
  }

  .modal__form-button {
    margin-top: 40px;
    width: 280px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 10px;
    background: var(--brand-color);
    border: none;
    color: black;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal__form-body-response.active {
    padding: 50px 20px 60px 20px;
    width: 320px;
    height: 530px;
    flex-shrink: 0;
    border-radius: 30px;
    background: #151517;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .sended-title {
    margin-top: 30px;
    font-size: 26px;
    text-align: center;
  }

  .sended-icon {
    margin-top: 60px;
    width: 150px;
    height: 150px;
    flex-shrink: 0;
  }

  .sended-description {
    margin-top: 14px;
    font-size: 21px;
    text-align: center;
  }
}
