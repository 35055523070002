.cover {
  width: 100%;
  height: 500px;
  position: relative;
}

.cover__image {
  width: 100%;
  height: 500px;
  object-fit: cover;
}

.cover__fade {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 410px;
  background: linear-gradient(0deg, #080808 0%, rgba(12, 12, 10, 0) 100%);
}

.cover__title {
  width: 673px;
  top: 180px;
  left: 50%;
  transform: translate(-50%);
  position: absolute;
  text-align: center;
  font-size: 42px;
}

@media (max-width: 1200px) {
  .cover {
    height: 380px;
  }

  .cover__image {
    height: 380px;
  }

  .cover__fade {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 380px;
    background: linear-gradient(0deg, #080808 0%, rgba(12, 12, 10, 0) 100%);
  }

  .cover__title {
    width: 320px;
    top: 150px;
    left: 50%;
    transform: translate(-50%);
    position: absolute;
    text-align: center;
    font-size: 32px;
  }
}
