.prices {
  margin-top: 120px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 80px;
}

.accordion {
  width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 0;
}

.accordion-item {
  width: 1200px;
  position: relative;
  list-style-type: none;
  cursor: pointer;
  background: #151517;
  border-radius: 12px;
}

.accordion-header {
  background-color: inherit;
  color: white;
  border: none;
  padding: 36px 0px 36px 50px;
  width: 100%;
  align-items: center;
  text-align: left;
  font-size: 24px;
  border-radius: 12px;
}

.accordion-body-header {
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  margin-top: 5px;
  border-top: 2px solid rgba(68, 214, 44, 0.5);
  border-bottom: 2px solid rgba(68, 214, 44, 0.5);
}

.accordion-body-header-wrapper {
  margin-left: 50px;
  width: 930px;
  display: flex;
}

.accordion-header-service,
.accordion-header-price,
.accordion-header-terms,
.accordion-header-garanty {
  font-size: 20px;
}

.accordion-header-price {
  margin-left: 324px;
}

.accordion-header-garanty,
.accordion-header-terms {
  margin-left: 150px;
}

.accordion-body {
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 2px solid rgba(68, 214, 44, 0.5);
}

.accordion-body:first-child {
  border-top: 2px solid rgba(68, 214, 44, 0.5);
}

.accordion-body:last-child {
  margin-bottom: 30px;
}

.accordion-body-wrapper {
  margin-left: 50px;
  text-align: left;
  color: var(--white-80);
  width: 991px;
  display: flex;
  align-items: center;
}

.accordion-collapse {
  height: 0px;
  overflow: hidden;
  transition: height 0.9s;
  overflow: hidden;
  -webkit-transition: height 0.9s;
}

.accordion-service {
  width: 301px;
}

.accordion-price {
  width: 105px;
  margin-left: 112px;
}

.accordion-garanty {
  margin-left: 94px;
  width: 93px;
}

.accordion-terms {
  margin-left: 150px;
  width: 120px;
}

.accordion-collapse.open {
  height: auto;
}

.plus__icon {
  width: 34px;
  height: 34px;
  top: 33px;
  position: absolute;
  right: 50px;
  transition: 0.4s ease-in-out;
  -webkit-transition: 0.4s ease-in-out;
  object-fit: cover;
}

.plus__icon.active {
  width: 34px;
  height: 34px;
  top: 33px;
  position: absolute;
  right: 50px;
  transform: rotate(45deg);
}

.accordion-price-mobile {
  display: none;
}

.accordion-garanty-mobile {
  display: none;
}

.accordion-terms-mobile {
  display: none;
}

@media (max-width: 1200px) {
  .prices {
    margin-top: 80px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }

  .prices__title {
    width: 246px;
    text-align: center;
  }

  .accordion {
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .accordion-item {
    width: unset;
    min-width: 100%;
    position: relative;
    list-style-type: none;
    cursor: pointer;
    background: #151517;
    border-radius: 0px;
  }

  .accordion-header {
    background-color: inherit;
    color: white;
    border: none;
    padding: 27px 0px 27px 20px;
    width: 100%;
    align-items: center;
    text-align: left;
    font-size: 21px;
    border-radius: 0px;
  }

  .accordion-body-header {
    display: none;
  }

  .accordion-body-header-wrapper {
    display: none;
  }

  .accordion-header-service,
  .accordion-header-price,
  .accordion-header-terms,
  .accordion-header-garanty {
    display: none;
  }

  .accordion-header-price {
    display: none;
  }

  .accordion-header-garanty,
  .accordion-header-terms {
    display: none;
  }

  .accordion-body {
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid rgba(68, 214, 44, 0.5);
  }

  .accordion-body:nth-child(2) {
    border-top: 1px solid rgba(68, 214, 44, 0.5);
  }

  .accordion-body:last-child {
    margin-bottom: 20px;
  }

  .accordion-body-wrapper {
    margin-left: 0px;
    text-align: unset;
    color: var(--white-80);
    width: 320px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }

  .accordion-collapse {
    height: 0px;
    overflow: hidden;
    transition: height 0.9s;
    overflow: hidden;
    -webkit-transition: height 0.9s;
  }

  .accordion-service {
    font-size: 14px;
    width: 320px;
  }

  .accordion-price {
    display: none;
  }

  .accordion-price-mobile {
    display: block;
    font-size: 14px;
  }

  .accordion-garanty {
    display: none;
  }

  .accordion-garanty-mobile {
    display: block;
    font-size: 14px;
  }

  .accordion-terms {
    display: none;
  }

  .accordion-terms-mobile {
    display: block;
    font-size: 14px;
  }

  .accordion-collapse.open {
    height: auto;
  }

  .plus__icon {
    width: 28px;
    height: 28px;
    top: 26px;
    position: absolute;
    right: 20px;
    transition: 0.4s ease-in-out;
    -webkit-transition: 0.4s ease-in-out;
    object-fit: cover;
  }

  .plus__icon.active {
    width: 28px;
    height: 28px;
    top: 26px;
    position: absolute;
    right: 20px;
    transform: rotate(45deg);
  }
}
