.main__form {
  margin-top: 120px;
  width: 100%;
  background: #151517;
  display: flex;
  justify-content: center;
  height: 394px;
}

.main__form-wrapper {
  margin-top: 80px;
  width: 1200px;
  display: flex;
  flex-direction: column;
}

.main__form-description {
  margin-top: 15px;
  width: 945px;
  font-size: 24px;
}

.main__form-form {
  margin-top: 50px;
  display: flex;
  gap: 50px;
}

.main__form-input {
  outline: none;
  border: none;
  border-radius: 10px;
  background: #080808;
  width: 298px;
  height: 58px;
  flex-shrink: 0;
  color: white;
  font-size: 20px;
  padding-left: 18px;
  border: 1px solid #080808;
}

.main__form-input::placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.main__form-input.error {
  border: 1px solid red;
  color: red;
}

.main__form-button {
  width: 300px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 10px;
  background: var(--brand-color);
  border: none;
  color: black;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .3s;
}

.main__form-button:hover {
  background: #24FF00;
}


@media (max-width: 1200px) {
  .main__form {
    margin-top: 80px;
    width: 100%;
    background: #151517;
    display: flex;
    justify-content: center;
    height: 590px;
  }

  .main__form-wrapper {
    margin-top: 40px;
    width: 320px;
    display: flex;
    flex-direction: column;
  }

  .main__form-description {
    margin-top: 12px;
    width: 320px;
    font-size: 21px;
  }

  .main__form-form {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .main__form-input {
    outline: none;
    border: none;
    border-radius: 10px;
    background: #080808;
    width: 318px;
    height: 58px;
    flex-shrink: 0;
    color: white;
    font-size: 20px;
    padding-left: 18px;
    border: 1px solid #080808;
  }

  .main__form-button {
    width: 320px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 10px;
    background: var(--brand-color);
    border: none;
    color: black;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}