.feedback__section {
  margin-top: 120px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.feedback__section-wrapper {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  margin-top: 170px;
}

.feedback {
  padding: 0px 25px 40px 25px;
  width: 350px;
  position: relative;
  border-radius: 20px;
  background: #151517;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.feedback__image {
  width: 180px;
  height: 180px;
  flex-shrink: 0;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -90px;
}

.feedback__title {
  margin-top: 115px;
  font-size: 24px;
}

.feedback__description {
  margin-top: 15px;
  text-align: center;
}

@media (max-width: 1200px) {
    .feedback__section-title  {
        width: 320px;
        text-align: center;
    }

    .feedback__section {
        margin-top: 80px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .feedback__section-wrapper {
        width: 320px;
        display: flex;
        flex-direction: column;
        justify-content: unset;
        margin-top: 115px;
        gap: 115px;
      }

      .feedback {
        padding: 0px 25px 35px 25px;
        width: 320px;
        position: relative;
        border-radius: 20px;
        background: #151517;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .feedback__image {
        width: 150px;
        height: 150px;
        flex-shrink: 0;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -75px;
      }

      .feedback__title {
        margin-top: 95px;
        font-size: 21px;
      }

      .feedback__description {
        margin-top: 10px;
        text-align: center;
      }
      
      
}