.repair {
  margin-top: 180px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.repair__title {
  align-self: center;
}

.repair__select {
  margin-top: 60px;
  width: 1200px;
  display: flex;
  justify-content: space-between;
  align-self: center;
}

.repair__select-item {
  width: 350px;
  height: 100px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #151517;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
  transition: 0.35s;
}

.repair__select-item.active {
  background-color: var(--brand-color);
  color: black;
  transition: 0.35s;
}

.repair__prices-photo {
  margin-top: 80px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.repair__prices-wrapper {
  width: 54%;
  height: 564px;
  flex-shrink: 0;
  border-radius: 0px 30px 30px 0px;
  background: #151517;
  position: relative;
}

.repair__photo-wrapper {
  width: 23%;
  height: 564px;
  flex-shrink: 0;
  border-radius: 30px 0px 0px 30px;
  background: #151517;
  position: relative;
  overflow: visible;
}

.repair__prices-texts {
  position: absolute;
  top: 48px;
  right: 9.25%;
  width: 585px;
  display: flex;
  flex-direction: column;
  gap: 36px;
}

.repair__prices-text {
  width: 585px;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.repair__prices-title {
  width: 331px;
  font-size: 20px;
}

.repair__prices-price {
  font-size: 20px;
}

.current__image {
  position: absolute;
  min-width: 480px;
  min-height: 480px;
  z-index: 3;
  left: -176px;
  top: 27px;
}

.repair__photo-elipse {
  position: absolute;
  bottom: 147px;
  right: 47.3%;
  width: 180px;
  height: 180px;
  flex-shrink: 0;
  background-color: var(--brand-color);
  filter: blur(130px);
  z-index: -1;
  backdrop-filter: none;
  -webkit-backdrop-filter: blur(160px);
}

@media (max-width: 1200px) {
  .repair {
    margin-top: 80px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .repair__title {
    width: 320px;
    align-self: center;
    text-align: center;
  }

  .repair__select {
    margin-top: 40px;
    width: 320px;
    display: flex;
    justify-content: unset;
    flex-direction: column;
    align-self: center;
    gap: 40px;
  }

  .repair__select-item {
    width: 320px;
    height: 90px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #151517;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 21px;
    transition: 0.35s;
  }

  .repair__prices-photo {
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: unset;
    gap: 210px;
  }

  .repair__prices-wrapper {
    width: 320px;
    height: auto;
    border-radius: unset;
    background: inherit;
    position: relative;
  }

  .repair__photo-wrapper {
    width: 100%;
    height: 160px;
    flex-shrink: 0;
    border-radius: unset;
    background: #151517;
    position: relative;
    overflow: visible;
  }

  .repair__prices-texts {
    position: static;
    top: unset;
    right: unset;
    width: 320px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .repair__prices-text {
    width: 320px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .repair__prices-title {
    width: 220px;
    font-size: 16px;
  }

  .repair__prices-price {
    font-size: 16px;
  }

  .current__image {
    width: 310px;
    height: 310px;
    flex-shrink: 0;
    position: absolute;
    min-width: 20px;
    min-height: 20px;
    z-index: 3;
    left: 50%;
    transform: translateX(-50%);
    top: -160px;
  }

  .repair__photo-elipse {
    position: absolute;
    bottom: 200px;
    right: unset;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 80px;
    flex-shrink: 0;
    background-color: var(--brand-color);
    filter: blur(120px);
    z-index: -1;
    backdrop-filter: none;
    -webkit-backdrop-filter: blur(120px);
  }
}
