.city__modal {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
}

.city__modal-body {
  padding: 80px 149px 80px 147px;
  width: 747px;
  height: 349px;
  flex-shrink: 0;
  border-radius: 30px;
  background: #151517;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.city__modal-text {
    margin-top: 40px;
    border: none;
    background-color: inherit;
    color: white;
    font-size: 24px;
    transition: .25s;
}

.city__modal-close {
    position: absolute;
    width: 40px;
    height: 40px;
    transform: rotate(45deg);
    right: 30px;
    top: 30px;
    cursor: pointer;
}

.city__modal-text:hover {
    color: var(--brand-color);
}

@media (max-width: 1200px) {
    .city__modal-body {
        padding: 60px 30px 60px 30px;
        width: 320px;
        height: unset;
      }

      .city__modal-title {
        font-size: 26px;
        text-align: center;
      }

      .city__modal-text {
        margin-top: 30px;
        border: none;
        background-color: inherit;
        color: white;
        font-size: 21px;
        transition: .25s;
    }

    .city__modal-close {
        position: absolute;
        width: 30px;
        height: 30px;
        transform: rotate(45deg);
        right: 20px;
        top: 20px;
        cursor: pointer;
    }
}